@import "../../theme/index.module.scss";
// @import "~typi";

.Cell {
  composes: cell well from "../../theme/layout.module.scss";
}

.Footer {
  background: $abbey;
  width:100%;
}

.CopyRights {
  padding-top: 1.9rem;
  font-size: 11px;
  line-height: 1.45rem;
  color: #FFFFFF;
  text-align: center;
  border-top: 1px solid white;
}

@include bp($large) {
  .CopyRights {
    padding-top: 1.34rem;
  }
}
